<template>
  <div v-if="uiState=='offerStateLogin'" class="popularOffers">
    <div class="fullScreenPage">
      <img id="fm_logo" src="../assets/Logo.png"/>
      <div v-on:click="closePopup()" class="greyText">
        <span class="underlinedText" style="font-size: 18px">Вернуться к выбору займа</span>
      </div>
      <div class="thinTitle">
        Статус заявки
      </div>
      <div class="header__desc" style="max-width:320px; margin-bottom: 10px">
        <span style="font-weight: 600">Оставьте свой номер телефона,</span>
        чтобы узнать статус заявки, а также получить предодобренные предложения по займу.
      </div>
      <div class="contentRow" style="align-items: flex-start; flex-direction: column">
        <div class="InputLabel">Номер телефона:</div>
        <masked-input  class="phoneInput" v-model="phoneNumber" mask="\+\7 111 111 11 11"  />
      </div>
      <div class="contentRow" style="align-items: flex-start; gap: 14px;" >
        <!--field type="checkbox" name="dataConcent" id="dataConcentBox" v-model="dataConcentCheck" @click="dataConcentCheck=!dataConcentCheck"/-->
        <el-checkbox class="dataConcentBox" v-model="dataConcentCheck" name="dataConcent" id="dataConcentBox"/>
        <label for="dataConcent" class="dataConcentLabel">Совершая любые действия на сайте, вы даете свое согласие на обработку персональных данных, согласие на получение иформационных материалов
        </label>
      </div>
      <div class="offer__bottom">
        <a
            target="_blank"
            class="offer__link"
            v-on:click="stateVerification()"
            :class="(dataConcentCheck==false || phoneNumberOutput.length<11)? 'disabledButton':''"
        >
          Продолжить
        </a>
      </div>
      <div class="loadingPopup" v-if="uiSubstate!=='none'" >
        <div class="loadingPopup__content"  v-if="uiSubstate=='loading'">
          <div class="header__desc" style="font-weight: 600">
            загрузка...
          </div>

          <div class="offer__bottom">
            <a
                target="_blank"
                class="offer__link"
                v-on:click="personalCheckComplete()"
                :class="(uiSubstate=='loading')? 'disabledButton':''"
            >
              ок
            </a>
          </div>

        </div>
        <div class="loadingPopup__content" style="border: 3px solid #68DA5E" v-if="uiSubstate=='inProgress'">
          <div class="header__desc" style="font-weight: 600" >
            {{ message }}
          </div>

          <div class="offer__bottom">
            <a
                target="_blank"
                class="offer__link"
                v-on:click="personalCheckComplete()"
                :class="(uiSubstate=='loading')? 'disabledButton':''"
            >
              ок
            </a>
          </div>

        </div>
        <div class="loadingPopup__content" v-if="uiSubstate=='notFound'" style="border: 3px solid #a6a6a6">
          <div class="header__desc" style="font-weight: 600; width: 300px" >
            Ваш запрос не был найден в нашей базе данных.
          </div>

          <div class="offer__bottom">
            <a
                target="_blank"
                class="offer__link"
                v-on:click="personalCheckComplete()"
                :class="(uiSubstate=='loading')? 'disabledButton':''"
            >
              ок
            </a>
          </div>

        </div>
      </div>
    </div>



  </div>
</template>

<script>

import MaskedInput from 'vue-masked-input'

export default {
  name: 'offerStatePage',
  components:{
    MaskedInput
  },
  props: {
    uiState: String,
  },
  methods:{
    closePopup(){
      this.uiSubstate='none';
      this.$emit('closePopup');
    },
    personalCheckComplete(){
      this.uiSubstate='none';
      this.$emit('personalCheckComplete');
    },
    getUrlParameters(){
      let paramString = window.location.href.split('?')[1];
      let queryString = new URLSearchParams(paramString);

      let outputObject= [];
      for (let pair of queryString.entries()) {
        outputObject[pair[0]]=  pair[1];
      }
      console.log(outputObject);
      return(outputObject);
    },
    stateChange(input){
      console.log(input);

      if (input.Success){
        this.uiSubstate='inProgress'
          this.message = input.message
      }
      else{
          this.uiSubstate='notFound'
      }
    },
    stateVerification(){
      this.uiSubstate='loading'
      let urlParams=this.getUrlParameters();
      console.log(Object.assign({
            'Accept': 'application/json',
            'Content-Type': 'application/json',},
          urlParams,
      ),)
      fetch('/api/check_loan',{
        method: 'POST',
        headers: Object.assign({
          'Accept': 'application/json',
          'Content-Type': 'application/json',},
          urlParams,
          ),
            body: JSON.stringify({'phone': this.phoneNumberOutput})
      })
          .then(response => response.json())
          .then(response => {this.stateChange(response)})
          //.then(this.stateChange('{"Success":"true"}'))

      //response.json())
          //.then(response => console.log(JSON.stringify(response)))
      /*const axios = require('axios').default;

      axios.post('/api/check_loan', {
        phone: JSON.stringify(this.phoneNumberOutput),
      })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });*/
    },

  },
  data() {
    return {
      phoneNumber: '',
      phoneNumberOutput:'',
      dataConcentCheck: false,
      uiSubstate: 'none',
        message: '',
    }
  },
  watch:{
    phoneNumber: {
      handler(){
        this.phoneNumberOutput=this.phoneNumber
        let length = this.phoneNumberOutput.length;
        for(let i=0;i<length;i++) {
          this.phoneNumberOutput = this.phoneNumberOutput.replace('+', '')
              .replace('(', '')
              .replace(')', '')
              .replace('-', '')
              .replace(' ', '')
              .replace('_', '');
        }
      }
    },
  }

}
</script>

<style lang="scss">
  .loadingPopup{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    &__content {
      z-index: 999999;
      //position: relative;
      //top: -300px;
      //left: 0px;
      width: 400px;
      height: 200px;
      border-radius: 15px;
      background: white;//rgba(45, 151, 196, 0.86);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
    }
  }

  .fullScreenPage{
    z-index: 99999;
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    background: #EFF1F9;
    display:flex;
    align-items: center;
    flex-direction: column;
    gap:24px;
    &__desc{
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */

      text-align: center;
      letter-spacing: 0.07em;

      /* Black */

      color: #181818;

      max-width:311px;
      margin-bottom: 24px

    }
  }
  .greyText{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: center;
    letter-spacing: 0.07em;

    color: #A3A3A3;
    cursor:pointer;
  }
  .thinTitle{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.07em;

    /* Black */

    color: #181818;
  }
  .InputLabel{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.07em;

    /* Black */

    color: #181818;
  }
  .dataConcentLabel{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 154%;
    /* or 18px */
    text-align: left;
    letter-spacing: 0.07em;

    /* Black */

    color: #181818;
    max-width: 259px
  }
  .phoneInput{
    margin-top: 12px;

    outline: none;
    width: 300px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.07em;
    border: none;
    background: #FEFEFF;
    box-shadow: 0px 232px 93px rgba(205, 207, 210, 0.01), 0px 131px 78px rgba(205, 207, 210, 0.05), 0px 58px 58px rgba(205, 207, 210, 0.09), 0px 12px 30px rgba(180, 185, 204, 0.26), 0px 0px 0px rgba(135, 135, 135, 0.42);
    border-radius: 10px;
    height:43px;


    color: #181818;

    padding-left: 21px;
    :hover{
      outline: 3px black;
    }
  }

  .dataConcentBox{
    box-shadow: 0px 232px 93px rgba(205, 207, 210, 0.01), 0px 131px 78px rgba(205, 207, 210, 0.05), 0px 58px 58px rgba(205, 207, 210, 0.09), 0px 12px 30px rgba(180, 185, 204, 0.26), 0px 0px 0px rgba(135, 135, 135, 0.42);
    .el-checkbox {
      color: #1f2d3d;
      position: relative;
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
    .el-checkbox__input {
      white-space: nowrap;
      cursor: pointer;
      outline: none;
      display: inline-block;
      line-height: 1;
      position: relative;
      vertical-align: middle;
    }
    .el-checkbox {
      color: #1f2d3d;
      position: relative;
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
    .el-checkbox__inner {
      display: inline-block;
      position: relative;
      border: none;//1px solid #bfcbd9;
      border-radius: 10px;
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      background-color: #fff;
      z-index: 1;
      transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
    }
    .el-checkbox__original {
      opacity: 0;
      outline: none;
      position: absolute;
      margin: 0;
      width: 0;
      height: 0;
      left:-999;
    }
    .el-checkbox__inner:after {
      box-sizing: content-box;
      content: "";
      //border: 2px solid black;
      border-left: 0;
      border-top: 0;
      height: 12px;//8px;
      width:  7px;//4px;
      left: 12px;//5px;
      top: 7px;//1px;
      position: absolute;

      //transform: rotate(45deg) scaleY(0);

      //transition: transform .15s cubic-bezier(.71,-.46,.88,.6) .05s;
      transform-origin: center;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #FEFEFF;//#20a0ff;
      border-color: #FEFEFF;//#0190fe;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner:after {
     transform: rotate(45deg) scaleY(1) ;
      transition: transform 0s;
      border: 2px solid black;
      border-left: 0;
      border-top: 0;
    }
    /*input {
      width: 32px;
      height: 32px;
      radius: 10px;
      background: #FEFEFF;
      border:none;
    }
    .is-checked{
      color:red !important;
      backgroun:none;
    }*/
  }
</style>

