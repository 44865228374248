<template>
  <div v-if="uiState=='completionBar'" class="popularOffers">
    <div class="popularOffers__popup">
      <div style="display:flex; flex-wrap: nowrap; flex-direction:row; justify-content:flex-start; width:100%">
        <div style="width:15px"/>
        <div class="completionBar" :style="{'width': barWidth+'%'}"/>
        <div style="width: 15px;"/>
      </div>
      <div class="popupTitle shortenedTop">
        Отправлено {{sentOffersCount}} из 3 заявок
      </div>
      <div class="popupDescription shortenedDescription">
        Для получения персонального предложения со 100% одобрением отправьте заявку в 3 компании
      </div>
      <div class="offer__bottom shortenedTop">
        <a
            target="_blank"
            class="offer__link"
            v-on:click="openPopularOffers()"
            :class="{ disabledButton: (sentOffersCount<3) }"
        >
          <span v-if="sentOffersCount<3"> Выбрано {{sentOffersCount}} из 3 </span>
          <span v-if="sentOffersCount>=3">получить</span>
        </a>
      </div>
      <div class="popupWhitespace"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'completionBar',
  components:{
  },
  props: {
    uiState: String,
    sentOffersCount: Number,
  },
  methods:{
    openPopularOffers(){
      if (this.sentOffersCount>=3) {
        this.$emit('openPopularOffers');
      }
    }
  },
  data(){
    return {
      barWidth: 5,
    }
  },
  watch:{
        sentOffersCount: {
          handler(){
            if (this.sentOffersCount<=3)
              this.barWidth=(5+this.sentOffersCount*95/3);
            else this.barWidth=(5+this.sentOffersCount*95/3);
          }
        }
      },

}
</script>
<style lang="scss">
  .disabledButton{
  color: #8F8F8F;
  background: #EAEAEA;
    cursor:default;
  }
  .completionBar{
    height:3px;
    background-color: #68DA5E;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px
  }
  .shortenedTop{
    @media(max-width: 593px) {
    margin-top: 4px;
    }
  }
  .shortenedDescription{
    @media(max-width: 593px) {
      margin-top: 4px;
      margin-left: 4px;
      margin-right: 4px;
    }
  }
</style>
