<template>
  <div class="offers" :class="mini ? 'paddingOverride' : ''">
    <div class="offers__list">
      <div v-for="offer in offers" :key="offer.id">
        <div
          v-if="!completedOffers.includes(offer.id) || mini == true"
          class="offers__offer offer"
          :class="mini ? 'shaded' : ''"
        >
          <div class="offer__logo">
            <img :src="offer.logo" :alt="offer.title" />
          </div>
          <div class="offer__list">
            <div class="offer__value">{{ offer.header }} ₽</div>
            <div offer__percentContainer>
              <div class="offer__percent">
                <span class="offer__percent__bold">
                  {{ offer.isFeatured ? "0" : "0.8" }}%</span
                >
                / день
              </div>
            </div>
            <div class="offer__time">
              {{ offer.subHeader }}
            </div>
          </div>
          <div class="offer__bottom">
            <a
              target="_blank"
              :href="offer.link"
              class="offer__link"
              v-on:click="initialOfferClick(offer)"
            >
              Получить деньги
            </a>
          </div>
          <div
            class="offer__time offer__ads"
            v-html="setAdsValue(offer.link, offer.org)"
          ></div>
        </div>
      </div>

      <div v-for="offer in offers" :key="offer.id">
        <div
          v-if="completedOffers.includes(offer.id) && mini == false"
          class="offers__offer offer"
        >
          <div class="offer__logo">
            <img :src="offer.logo" :alt="offer.title" />
          </div>
          <div class="offer__list">
            <div class="offer__value">{{ offer.header }} ₽</div>
            <div offer__percentContainer>
              <div class="offer__percent">
                <span class="offer__percent__bold">
                  {{ offer.isFeatured ? "0" : "1" }}%</span
                >
                / день
              </div>
            </div>
            <div class="offer__time">
              {{ offer.subHeader }}
            </div>
          </div>
          <div class="offer__bottom">
            <a
              target="_blank"
              :href="offer.link"
              class="offer__link disabledButton"
              style="cursor: pointer"
            >
              Получить деньги
            </a>
          </div>
          <div
            class="offer__time offer__ads"
            v-html="setAdsValue(offer.link, offer.org)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OffersList",
  props: {
    offers: [],
    mini: Boolean,
    completedOffers: [],
  },
  data() {
    return {};
  },
  methods: {
    // async sentOfferID(id) {
    // if (localStorage.getItem("ids-save")) return;
    // const cached = localStorage.getItem("ids-save")
    //   ? JSON.parse(localStorage.getItem("ids-save") ?? "")
    //   : null;
    // console.log(cached.some((item) => item.id === id));

    // },
    async initialOfferClick(offer) {
      window.onpopstate = () => {};


      //cached offer
      let idsStorage = [];

      const cached = localStorage.getItem("ids-save")
        ? JSON.parse(localStorage.getItem("ids-save") ?? "")
        : [];

      if (!cached.some((item) => item.id === offer.id)) {
        try {
          const response = await fetch(`/api/click?offer_id=${offer.clickId}`);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      }

      if (cached) idsStorage = cached;

      if (cached && cached.filter((obj) => obj.id === offer.id).length !== 0)
        return;

      idsStorage.push(offer);
      localStorage.setItem("ids-save", JSON.stringify(idsStorage));
      //--cached offer

      let newCompletedOffers = this.completedOffers;
      console.log(newCompletedOffers);
      console.log(this.completedOffers);
      if (!this.completedOffers.includes(offer.id)) {
        newCompletedOffers.push(offer.id);
        localStorage.setItem("sentOffers", JSON.stringify(newCompletedOffers));
      }
      console.log(JSON.parse(localStorage.getItem("sentOffers")));
      this.$emit("offerSent");
    },
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    setAdsValue(link, org) {
      const linkParams = new URL(link).searchParams;
      const param = linkParams.get("erid")
        ? linkParams.get("erid").split("?")[0]
        : "LjN8K" + this.makeid(4);

      return `Реклама ${org} <br> Erid: ${param}`;
    },
  },
  /*watch:{
    sentOffersCount: {
      handler(){
        if (this.sentOffersCount<=3)
          this.barWidth=(5+this.sentOffersCount*95/3);
        else this.barWidth=(5+this.sentOffersCount*95/3);
      }
    }
  },*/
};
</script>

<style lang="scss">
.offers {
  padding-top: 24px;
  //padding-left: 20px;
  //padding-right: 20px;
}
.paddingOverride {
  padding-top: 12px;
}

.offers__list {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0px;

  @media (min-width: 1024px) {
    width: 1024px !important;
    margin: 0 auto;
  }
}
.shaded {
  box-shadow: 0px 2px 15px rgba(60, 51, 80, 0.1),
    0px 232px 93px rgba(205, 207, 210, 0.01),
    0px 131px 78px rgba(205, 207, 210, 0.05),
    0px 58px 58px rgba(205, 207, 210, 0.09),
    0px 15px 30px rgba(180, 185, 204, 0.08),
    0px 0px 0px rgba(135, 135, 135, 0.42);
}

.offer {
  background: #fafafa;
  border-radius: 10px;
  color: #181818;
  box-sizing: border-box;
  //padding-top: 26px;
  // padding-left: 16px;
  // padding-right: 16px;
  padding: 10px 16px;
  min-width: 179px;
  margin: 5px;
  width: 179px;
  min-height: 252px;

  &__value {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.07em;
    margin-top: 12px;
    white-space: nowrap;
    /* Black */

    color: #181818;
  }

  &__percentContainer {
    margin-top: 13px;
  }

  &__percent {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 127.5%;
    /* or 20px */

    text-align: center;
    letter-spacing: 0.07em;

    margin: auto;
    margin-top: 6px;
    padding: 5px;
    text-align: center;
    width: 100px;
    background-color: #eff1f9;

    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #181818;

    &__bold {
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.07em;

      /* Black */

      color: #181818;
    }
  }

  &__time {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 127.5%;
    /* or 20px */

    text-align: center;
    letter-spacing: 0.07em;
    margin-top: 13px;

    /* Black */

    color: #181818;
  }

  &__ads {
    margin: 12px 0 0 0;
    font-size: 8px;
    line-height: 14px;
  }

  &__logo {
    text-align: center;
    margin-bottom: 2px;
    height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    img {
      width: auto;
      max-height: 44px; //34px;
      max-width: 147px;
    }
  }

  &__bottom {
    margin-top: 12px;
    display: flex;
    align-content: center;
    cursor: pointer;
  }

  &__list {
    font-size: 8px;

    & > p {
      display: flex;
      margin-bottom: 2px;

      &::before {
        min-width: 11px;
        max-width: 11px;
        height: 11px;
        content: "";
        display: block;
        margin-right: 10px;
        background: url("../static/icons/check.svg") no-repeat center;
        background-size: contain;
      }
    }
  }

  &__link {
    display: block;
    box-sizing: border-box;
    //padding: 8px 0px 9px 0px;
    padding-top: 8px;
    //padding-bottom:9px;
    text-decoration: none;

    width: 160px;
    height: 41px;

    background: #5e3cf8;
    border-radius: 10px;

    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 154%;
    text-align: center;

    color: #fefeff;
    cursor: pointer;
  }
}

.offers__title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
}
</style>
